import { createSelector } from "@reduxjs/toolkit";
import { selectUserSetting } from "../userSettings/selectors";
var selectPaymentTypes = function (state) { return state.paymentTypes; };
var selectDefaultPaymentType = function (companyId) {
    return selectUserSetting(companyId, "defaultPaymentType");
};
var selectPaymentTypesByCompanyId = function (companyId) {
    return createSelector(selectPaymentTypes, function (paymentTypes) { return paymentTypes[companyId] || []; });
};
var selectEnabledPaymentTypes = function (companyId) {
    return createSelector(selectPaymentTypesByCompanyId(companyId), function (paymentTypes) {
        return paymentTypes.filter(function (p) { return p.enabled; });
    });
};
export { selectPaymentTypes, selectDefaultPaymentType, selectEnabledPaymentTypes, selectPaymentTypesByCompanyId, };
