import React from "react";
import { TableRow, TableCell, Typography } from "@material-ui/core";
import { currency } from "../../../utils";
import styles from "../Receipts.module.css";
import { MonthlySummary } from "../types";

interface SummaryRowProps {
  summary: MonthlySummary;
}

const SummaryRow = ({ summary }: SummaryRowProps) => {
  const date = new Date(summary.date);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  return (
    <TableRow>
      <TableCell>
        <Typography variant="subtitle2">Avstämningsbelopp</Typography>
      </TableCell>
      <TableCell className={styles.dateCell} align="right">
        <Typography variant="subtitle2">
          {year}-{month}
          <br />
          {currency.SEK(summary.total)}
        </Typography>
      </TableCell>
      <TableCell></TableCell>
    </TableRow>
  );
};

export default SummaryRow;
