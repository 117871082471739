import React, { useState, useEffect, ChangeEvent } from "react";
import { connect, ConnectedProps } from "react-redux";
import { PageLayout } from "../../../components";
import {
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  Box,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { Alert } from "@material-ui/lab";
import axios from "axios";
import { State } from "../../../store";
import * as voucherSeriesActions from "@kvittoapp/ducks/dist/voucherSeries";
import * as companyActions from "@kvittoapp/ducks/dist/company";
import * as companiesActions from "@kvittoapp/ducks/dist/companies";
import { VoucherSeries } from "@kvittoapp/ducks/dist/voucherSeries/models";
import api from "@kvittoapp/api-client";
import Confirmation from "../../../components/Confirmation/Confirmation";
import { selectVoucherSeriesByCompanyId } from "@kvittoapp/ducks/dist/voucherSeries/selectors";
import { selectActiveCompany } from "../../../ducks/active-company/selectors";
import { selectCompanyById } from "@kvittoapp/ducks/dist/companies/selectors";

const mapStateToProps = (state: State) => {
  const companyId = selectActiveCompany(state);
  return {
    voucherSeries: selectVoucherSeriesByCompanyId(companyId)(state),
    companyId,
    selectedVoucherSeries: state.company.voucherSeries,

    company: selectCompanyById(companyId)(state),
  };
};

const mapDispatchToProps = {
  getVoucherSeries: voucherSeriesActions.getVoucherSeries,
  getCompany: companyActions.getCompany,
  getCompanies: companiesActions.getCompanies,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

type FormData = {
  displayCostCenterInput: boolean;
  voucherSeries: VoucherSeries;
};

interface CompanySettingsProps extends PropsFromRedux {
  voucherSeries: VoucherSeries[];
  companyId: string;
  selectedVoucherSeries: string;
}

const CompanySettings = ({
  companyId,
  company,
  voucherSeries,
  selectedVoucherSeries,
  getVoucherSeries,
  getCompany,
  getCompanies,
}: CompanySettingsProps) => {
  const initialFormData = {
    displayCostCenterInput: company?.displayCostCenterInput ?? true,
    voucherSeries: company?.voucherSeries ?? "",
  };
  const [formData, setFormData] = useState(initialFormData);
  const [submitting, setSubmitting] = useState(false);
  const [updateSuccess, setUpdatedSuccess] = useState(false);
  const [error, setError] = useState<undefined | string>(undefined);

  const onDisplayCostCenterInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, displayCostCenterInput: e.target.checked });
  };
  const onSelectChange =
    (name: keyof FormData) => (e: React.ChangeEvent<{ value: unknown }>) => {
      const value = e.target.value as string;
      setFormData({ ...formData, [name]: value });
    };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitting(true);
    setError(undefined);

    try {
      await api.updateCompany(companyId, {
        displayCostCenterInput: formData.displayCostCenterInput,
        voucherSeries: formData.voucherSeries,
        enabled: true,
        accountant: company?.accountant ?? "",
      });
      setUpdatedSuccess(true);
      setFormData(initialFormData);
      getCompany({ companyId });
      getCompanies();
    } catch (e) {
      setUpdatedSuccess(false);
      if (axios.isAxiosError(e)) {
        setError(e.message);
      } else {
        setError("Ett okänt fel inträffade. Försök igen.");
      }
    } finally {
      setSubmitting(false);
    }
  };
  useEffect(() => {
    getVoucherSeries({ companyId });
    getCompany({ companyId });
  }, [companyId, getVoucherSeries, getCompany]);
  useEffect(() => {
    const selectedSeriesExists = !!voucherSeries.find(
      (v) => v.id === selectedVoucherSeries
    );
    if (!selectedSeriesExists) {
      return;
    }
    setFormData((formData) => ({
      ...formData,
      voucherSeries: selectedVoucherSeries,
    }));
  }, [selectedVoucherSeries, voucherSeries]);
  if (updateSuccess) {
    return (
      <Confirmation
        title="Företagsinställningar uppdaterade"
        message="Inställningarna är uppdaterade"
      />
    );
  }

  return (
    <PageLayout title="Allmänna företagsinställningar">
      <form onSubmit={onSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel htmlFor="voucherSeries">Verifikatserie</InputLabel>
              <Select
                value={formData.voucherSeries}
                onChange={onSelectChange("voucherSeries")}
                native
                inputProps={{
                  name: "voucherSeries",
                  id: "voucherSeries",
                }}
              >
                <option value="" />
                {[...voucherSeries]
                  .sort((a, b) => (a.id > b.id ? 1 : -1))
                  .map((v: VoucherSeries) => (
                    <option value={v.id} key={v.id}>
                      {v.id} - {v.description}
                    </option>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.displayCostCenterInput}
                  color="primary"
                  onChange={onDisplayCostCenterInputChange}
                />
              }
              label="Visa kostnadsställe i kvittoformuläret"
            />
          </Grid>
          <Grid item xs={12}>
            {error && (
              <Box marginBottom={1}>
                <Alert severity="error">{error}</Alert>
              </Box>
            )}
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Button
                component={Link}
                to="/settings"
                variant="contained"
                color="default"
                disabled={submitting}
                fullWidth
              >
                Avbryt
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={submitting}
                fullWidth
              >
                {submitting ? "Ändrar" : "Ändra"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </PageLayout>
  );
};

export default connector(CompanySettings);
