import React from "react";
import RequestResetPassword from "./RequestResetPassword";
import ResetPasswordForm from "./ResetPasswordForm";

interface ResetPasswordProps {
  token?: string;
}

const ResetPassword = ({ token }: ResetPasswordProps) => {
  if (token) {
    return <ResetPasswordForm token={token} />;
  }
  return <RequestResetPassword />;
};

export default ResetPassword;
