import React, { useEffect, useState, ChangeEvent } from "react";
import { connect, ConnectedProps } from "react-redux";
import * as paymentTypesActions from "@kvittoapp/ducks/dist/paymentTypes";
import { PaymentType } from "@kvittoapp/ducks/dist/paymentTypes/models";
import { State } from "../../../store";
import { PageLayout } from "../../../components";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import styles from "./PaymentTypesSettings.module.css";
import { selectPaymentTypesByCompanyId } from "@kvittoapp/ducks/dist/paymentTypes/selectors";
import { selectActiveCompany } from "../../../ducks/active-company/selectors";
import { useNavigate } from "react-router-dom";

const mapStateToProps = (state: State) => {
  const companyId = selectActiveCompany(state);
  return {
    paymentTypes: selectPaymentTypesByCompanyId(companyId)(state),
    companyId,
  };
};

const mapDispatchToProps = {
  get: paymentTypesActions.getPaymentTypes,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface PaymentTypesSettingsProps extends PropsFromRedux {
  paymentTypes: PaymentType[];
  companyId: string;
}

const PaymentTypesSettings = ({
  get,
  paymentTypes,
  companyId,
}: PaymentTypesSettingsProps) => {
  const [showOnlyEnabled, setShowOnlyEnabled] = useState(true);
  useEffect(() => {
    get({ companyId });
  }, [get, companyId]);
  const onShowOnlyEnabledChange = (e: ChangeEvent<HTMLInputElement>) => {
    setShowOnlyEnabled(e.target.checked);
  };
  return (
    <PageLayout
      title="Betalsätt"
      button={{
        title: "Lägg till",
        url: "/settings/company/payment-types/add",
      }}
    >
      <FormControlLabel
        control={
          <Switch
            checked={showOnlyEnabled}
            onChange={onShowOnlyEnabledChange}
            name="checkedB"
            color="primary"
          />
        }
        label="Visa bara aktiva"
      />
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Beskrivning</TableCell>
              <TableCell>Konto</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {[...paymentTypes]
              .filter(
                (paymentType) =>
                  (showOnlyEnabled && paymentType.enabled) || !showOnlyEnabled
              )
              .sort((a, b) => (a.description > b.description ? 1 : -1))
              .map((paymentType: PaymentType) => (
                <Row paymentType={paymentType} key={paymentType.id} />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </PageLayout>
  );
};
interface RowProps {
  paymentType: PaymentType;
}

const Row = ({ paymentType }: RowProps) => {
  const [redirect, setRedirect] = useState<undefined | string>(undefined);
  const navigate = useNavigate();
  if (redirect) {
    navigate(redirect);
  }
  return (
    <TableRow
      onClick={() =>
        setRedirect(`/settings/company/payment-types/${paymentType.id}/edit`)
      }
      className={styles.row}
    >
      <TableCell>{paymentType.description}</TableCell>
      <TableCell>{paymentType.accountNumber}</TableCell>
      <TableCell padding="none">
        <ChevronRightIcon />
      </TableCell>
    </TableRow>
  );
};

export default connector(PaymentTypesSettings);
