import {
  List,
  ListItem,
  Divider,
  ListItemText,
  ListSubheader,
} from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { connect } from "react-redux";
import { State } from "../../store";
import { PageLayout } from "../../components";
import { Link } from "react-router-dom";
import { selectActiveCompany } from "../../ducks/active-company/selectors";
import { selectUserRoleByCompanyId } from "@kvittoapp/ducks/dist/users/selectors";

interface SettingsProps {
  userRole: string | undefined;
}

const Settings = ({ userRole }: SettingsProps) => (
  <PageLayout title="Inställningar">
    <List component="nav">
      <ListSubheader>Mina inställningar</ListSubheader>
      <ListItem button component={Link} to="/settings/user/default-choices">
        <ListItemText primary="Standardval" />
        <ChevronRightIcon />
      </ListItem>
      <ListItem button component={Link} to="/settings/user/password">
        <ListItemText primary="Ändra lösenord" />
        <ChevronRightIcon />
      </ListItem>
      <ListItem button component={Link} to="/auth/logout">
        <ListItemText primary="Logga ut" />
        <ChevronRightIcon />
      </ListItem>
      {userRole! === "manager" && (
        <>
          <Divider />
          <ListSubheader>Företagsinställningar</ListSubheader>
          <ListItem button component={Link} to="/settings/company/">
            <ListItemText primary="Allmänna inställningar" />
            <ChevronRightIcon />
          </ListItem>
          <ListItem button component={Link} to="/settings/company/users">
            <ListItemText primary="Användare" />
            <ChevronRightIcon />
          </ListItem>
          <ListItem button component={Link} to="/settings/company/cost-types">
            <ListItemText primary="Kostnadstyper" />
            <ChevronRightIcon />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/settings/company/payment-types"
          >
            <ListItemText primary="Betalsätt" />
            <ChevronRightIcon />
          </ListItem>
        </>
      )}
    </List>
  </PageLayout>
);
const mapStateToProps = (state: State) => {
  const companyId = selectActiveCompany(state);
  const userId = state.auth.id ? state.auth.id : "";
  return {
    userRole: selectUserRoleByCompanyId({ id: userId, companyId })(state),
  };
};

export default connect(mapStateToProps)(Settings);
