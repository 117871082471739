import React from "react";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { green } from "@material-ui/core/colors";
import { PageLayout } from "../";
import { Typography, Box } from "@material-ui/core";

type ConfirmationProps = {
  title: string;
  message?: string;
  children?: React.ReactNode;
};

const Confirmation = ({ title, message, children }: ConfirmationProps) => (
  <PageLayout title={title}>
    <Box marginBottom={1} textAlign="center">
      <CheckCircleOutlineIcon style={{ color: green[500], fontSize: 50 }} />
      <Box marginBottom={5}>
        <Typography component="h5">{message}</Typography>
      </Box>
      {children}
    </Box>
  </PageLayout>
);

export default Confirmation;
