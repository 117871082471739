import React, { useState, ChangeEvent } from "react";
import { useSelector } from "react-redux";
import { PageLayout } from "../../../components";
import { TextField, Button, Grid } from "@material-ui/core";
import api from "@kvittoapp/api-client";
import { Link, useNavigate } from "react-router-dom";
import { Alert } from "@material-ui/lab";
import styles from "./Add.module.css";
import { selectActiveCompany } from "../../../ducks/active-company/selectors";
import axios from "axios";

type FormData = {
  email: string;
};
type ResponseError = string;

const AddUser = () => {
  const initialFormData: FormData = {
    email: "",
  };
  const initialError: ResponseError = "";
  const [formData, setFormData] = useState(initialFormData);
  const [submitting, setSubmitting] = useState(false);
  const [addSuccess, setAddSuccess] = useState(false);
  const [error, setError] = useState(initialError);
  const navigate = useNavigate();
  const companyId = useSelector(selectActiveCompany);

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitting(true);
    setError(initialError);
    try {
      await api.createUser(companyId!, {
        email: formData.email,
        enabled: true,
        role: "staff",
      });
      setFormData(initialFormData);
      setSubmitting(false);
      setAddSuccess(true);
    } catch (e) {
      setAddSuccess(false);
      if (axios.isAxiosError(e)) {
        setError(e.message);
      }
    }
  };
  const onFormDataChange =
    (name: keyof FormData) => (e: ChangeEvent<HTMLInputElement>) => {
      setFormData({ ...formData, [name]: e.target.value });
    };

  if (addSuccess) {
    navigate("/settings/company/users");
  }

  return (
    <PageLayout title="Lägga till användare">
      <form onSubmit={onSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              autoFocus
              type="text"
              label="E-postadress"
              name="name"
              value={formData.email}
              onChange={onFormDataChange("email")}
            />
          </Grid>
          <Grid item xs={12}>
            {error && (
              <div className={styles.errorMessage}>
                <Alert severity="error">{error}</Alert>
              </div>
            )}
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Button
                component={Link}
                to="/settings/company/users"
                variant="contained"
                color="default"
                disabled={submitting}
                fullWidth
              >
                Avbryt
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={submitting}
                className={styles.submitButton}
                fullWidth
              >
                Lägg till
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </PageLayout>
  );
};

export default AddUser;
