type Messages = {
  [key: string]: string;
};
const messages: Messages = {
  COMPANY_EXISTS:
    "Det finns redan ett företag samma organisationsnummer i systemet",
  COMPANY_NOT_FOUND: "Företaget kunde inte hittas i systemet.",
  FORTNOX_FORBIDDEN:
    "Kopplingen till Fortnox är ogiltig. Testa göra en ny aktivering",
  LOGIN_GENERIC:
    "Något blev fel. Kontrollera att du skrivit rätt användarnamn och lösenord",
  UNKNOWN_ERROR: "Ett okänt fel inträffade",
  USER_EXISTS: "Angiven e-postadress är redan kopplad till ett företag",
  RESET_PASSWORD_TOKEN_NOT_FOUND:
    "Länken du klickade på för att komma in är ogiltig. Begär att få återställa lösenordet igen.",
  RESET_PASSWORD_TOKEN_EXPIRED:
    "Länken du klickade på för att komma in är ogiltig. Begär att få återställa lösenordet igen.",
};

const getMessage = (key: string) => {
  if (messages[key]) {
    return messages[key];
  }
  return "";
};

export default getMessage;
