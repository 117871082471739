import { createSlice } from "@reduxjs/toolkit";
var initialState = {};
var authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        add: function (state, action) {
            state.id = action.payload.id;
        },
        remove: function (state) {
            state.id = undefined;
        },
    },
});
var reducer = authSlice.reducer;
var _a = authSlice.actions, add = _a.add, remove = _a.remove;
export { add, remove };
export default reducer;
