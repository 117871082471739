import * as React from "react";
import { State } from "../../../store";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { PageLayout } from "../../../components";
import { Link, useParams } from "react-router-dom";
import * as voucherActions from "@kvittoapp/ducks/dist/vouchers";
import { File } from "@kvittoapp/ducks/dist/vouchers/models";
import { Box, Typography } from "@material-ui/core";
import { currency } from "../../../utils";
import { selectVoucherById } from "@kvittoapp/ducks/dist/vouchers/selectors";
import { selectActiveCompany } from "../../../ducks/active-company/selectors";
import FileComponent from "../../../components/File";
import { useState } from "react";

const mapDispatchToProps = {
  getVouchers: voucherActions.getVouchers,
};
const mapStateToProps = () => ({});
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface ViewReceiptProps extends PropsFromRedux {}

const ViewReceipt = ({ getVouchers }: ViewReceiptProps) => {
  const { receiptId } = useParams();
  const companyId = useSelector(selectActiveCompany);
  const userId = useSelector<State>((state) => state.auth.id);
  const receipt = useSelector(
    selectVoucherById({ id: receiptId as string, companyId })
  );
  const [openFile, setOpenFile] = useState<undefined | string>(undefined);
  React.useEffect(() => {
    getVouchers({ companyId, userId: userId as string });
  }, [getVouchers, companyId, userId]);
  if (!receipt) {
    return null;
  }
  return (
    <PageLayout backButton={{ url: "/receipts" }} title="Granska kvitto">
      {receipt.costCenter && (
        <Row title="Kostnadsställe" content={receipt.costCenter} />
      )}
      {receipt.project && <Row title="Projekt" content={receipt.project} />}
      <Row title="Kostnadstyp" content={receipt.CostType.description} />
      <Row title="Betalsätt" content={receipt.PaymentType.description} />
      <Row title="Transaktionsdatum" content={receipt.transactionDate} />
      <Row title="Beskrivning" content={receipt.description} />
      <Row title="Totalt" content={currency.SEK(receipt.total)} />
      <Row title="Moms" content={currency.SEK(receipt.vat)} />
      {receipt.Files.length > 0 &&
        receipt.Files.map((file: File, i: number) => (
          <Row title={`Fil ${i + 1}`} key={file.id}>
            <FileComponent
              open={openFile === file.id}
              file={file}
              onClose={() => setOpenFile(undefined)}
            />
            <Link to="#" onClick={() => setOpenFile(file.id)}>
              {file.name}
            </Link>
          </Row>
        ))}
    </PageLayout>
  );
};

interface RowProps {
  title: string;
  content?: string | number;
  children?: React.ReactNode;
}

const Row = ({ title, content, children }: RowProps) => (
  <Box
    marginBottom={1}
    overflow="hidden"
    whiteSpace="nowrap"
    textOverflow="ellipsis"
  >
    <Typography variant="subtitle2">{title}</Typography>
    {content}
    {children}
  </Box>
);

export default connector(ViewReceipt);
