import React, { useEffect, useState } from "react";
import { Route, useLocation, Routes, Navigate } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import Signup, { ReActivateConfirmation } from "./pages/signup";
import { Login, Logout, ActiveCompany } from "./pages/auth";
import Receipt from "./pages/receipt/Receipt";
import Receipts, { ViewReceipt } from "./pages/receipts";
import * as authActions from "@kvittoapp/ducks/dist/auth";
import * as costTypeActions from "@kvittoapp/ducks/dist/costTypes";
import * as paymentTypeActions from "@kvittoapp/ducks/dist/paymentTypes";
import * as costCentersActions from "@kvittoapp/ducks/dist/costCenters";
import * as projectsActions from "@kvittoapp/ducks/dist/projects";
import * as userSettingsActions from "@kvittoapp/ducks/dist/userSettings";
import * as usersActions from "@kvittoapp/ducks/dist/users";
import { PrivateRoute, PageLayout } from "./components";
import {
  createTheme,
  CssBaseline,
  ThemeProvider,
  useMediaQuery,
} from "@material-ui/core";
import api from "@kvittoapp/api-client";
import { Loader } from "./components";
import Settings, {
  AddCostType,
  CostTypesSettings,
  AddUser,
  UsersSettings,
  PaymentTypesSettings,
  AddPaymentType,
  ChangePassword,
  EditPaymentType,
  EditCostType,
  CompanySettings,
  DefaultChoices,
  EditUser,
} from "./pages/settings";
import { ResetPassword } from "./pages/reset-password";
import { State } from "./store";
import { selectActiveCompany } from "./ducks/active-company/selectors";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const mapStateToProps = (state: State) => ({
  activeCompany: selectActiveCompany(state),
});

const mapDispatchToProps = {
  addAuth: authActions.add,
  getPaymentTypes: paymentTypeActions.getPaymentTypes,
  getCostTypes: costTypeActions.getCostTypes,
  getCostCenters: costCentersActions.getCostCenters,
  getUserSettings: userSettingsActions.get,
  getCompanyUsers: usersActions.getCompanyUsers,
  getProjects: projectsActions.getProjects,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface AppProps extends PropsFromRedux {}

const App = ({
  activeCompany,
  addAuth,
  getPaymentTypes,
  getCostTypes,
  getCostCenters,
  getUserSettings,
  getCompanyUsers,
  getProjects,
}: AppProps) => {
  const [loading, setLoading] = useState(true);
  const query = useQuery();
  useEffect(() => {
    const bootstrap = async () => {
      try {
        setLoading(true);
        const auth = await api.getAuth();
        if (auth.status !== 200) {
          setLoading(false);
          return;
        }
        addAuth(auth.data);
        const companyId = activeCompany;
        const userId = auth.data.id;
        if (!companyId) {
          setLoading(false);
          return;
        }

        await Promise.all([
          getPaymentTypes({ companyId }),
          getCostTypes({ companyId }),
          getCostCenters({ companyId }),
          getUserSettings({ companyId, userId }),
          getCompanyUsers({ companyId }),
          getProjects({ companyId }),
        ]);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };
    bootstrap();
  }, [
    activeCompany,
    addAuth,
    getPaymentTypes,
    getCostTypes,
    getCostCenters,
    getUserSettings,
    getCompanyUsers,
    getProjects,
  ]);

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          type: prefersDarkMode ? "dark" : "light",
          primary: {
            main: "#3161a3",
          },
        },
      }),
    [prefersDarkMode]
  );

  if (loading) {
    return (
      <ThemeProvider theme={theme}>
        <PageLayout title="Enkla Kvitton">
          <CssBaseline />
          <Loader />
        </PageLayout>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route
          path="/signup/reactivate/confirmation"
          element={<ReActivateConfirmation />}
        />
        <Route
          path="/signup"
          element={<Signup code={query.get("code") as string} />}
        />
        <Route
          path="/auth/login"
          element={
            <Login
              redirectPath="/auth/active-company"
              initialEmail={query.get("email")}
            />
          }
        />
        <Route
          path="/reset-password"
          element={<ResetPassword token={query.get("token") as string} />}
        />
        <Route element={<PrivateRoute />}>
          <Route
            path="/auth/active-company"
            element={<ActiveCompany redirectPath="/receipts/add" />}
          />
          <Route path="/receipts/add" element={<Receipt />} />
          <Route path="/receipts/:receiptId" element={<ViewReceipt />} />
          <Route path="/receipts" element={<Receipts />} />
          <Route path="/auth/logout" element={<Logout />} />
          <Route
            path="/settings/user/default-choices"
            element={<DefaultChoices />}
          />
          <Route path="/settings/user/password" element={<ChangePassword />} />
          <Route path="/settings" element={<Settings />} />
        </Route>
        <Route element={<PrivateRoute requiredRole="manager" />}>
          <Route path="/settings/company/users/add" element={<AddUser />} />
          <Route
            path="/settings/company/users/:userId/edit"
            element={<EditUser />}
          />
          <Route path="/settings/company/users" element={<UsersSettings />} />
          <Route
            path="/settings/company/cost-types/:costTypeId/edit"
            element={<EditCostType />}
          />
          <Route
            path="/settings/company/cost-types/add"
            element={<AddCostType />}
          />
          <Route
            path="/settings/company/cost-types"
            element={<CostTypesSettings />}
          />
          <Route
            path="/settings/company/payment-types/add"
            element={<AddPaymentType />}
          />
          <Route
            path="/settings/company/payment-types/:paymentTypeId/edit"
            element={<EditPaymentType />}
          />
          <Route
            path="/settings/company/payment-types"
            element={<PaymentTypesSettings />}
          />
          <Route path="/settings/company/" element={<CompanySettings />} />
        </Route>
        <Route path="/" element={<Navigate to="/receipts/add" />} />
      </Routes>
    </ThemeProvider>
  );
};
export default connector(App);
