import React, { useState, useEffect } from "react";
import { PageLayout } from "../../../../components";
import {
  FormControl,
  InputLabel,
  Grid,
  Button,
  Select,
  Box,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { State } from "../../../../store";
import { connect, ConnectedProps } from "react-redux";
import { Alert } from "@material-ui/lab";
import { CostType } from "@kvittoapp/ducks/dist/costTypes/models";
import { PaymentType } from "@kvittoapp/ducks/dist/paymentTypes/models";
import { CostCenter } from "@kvittoapp/ducks/dist/costCenters/models";
import * as costTypesActions from "@kvittoapp/ducks/dist/costTypes";
import * as costCentersActions from "@kvittoapp/ducks/dist/costCenters";
import * as paymentTypesActions from "@kvittoapp/ducks/dist/paymentTypes";
import * as userSettingsActions from "@kvittoapp/ducks/dist/userSettings";
import Confirmation from "../../../../components/Confirmation/Confirmation";
import { UserSettingType } from "@kvittoapp/ducks/dist/userSettings/models";
import {
  selectDefaultCostType,
  selectCostTypesByCompanyId,
} from "@kvittoapp/ducks/dist/costTypes/selectors";
import {
  selectDefaultCostCenter,
  selectEnabledCostCentersByCompanyId,
} from "@kvittoapp/ducks/dist/costCenters/selectors";
import {
  selectPaymentTypesByCompanyId,
  selectDefaultPaymentType,
} from "@kvittoapp/ducks/dist/paymentTypes/selectors";
import { selectActiveCompany } from "../../../../ducks/active-company/selectors";

const mapStateToProps = (state: State) => {
  const companyId = selectActiveCompany(state);
  return {
    costCenters: selectEnabledCostCentersByCompanyId(companyId)(state),
    paymentTypes: selectPaymentTypesByCompanyId(companyId)(state),
    costTypes: selectCostTypesByCompanyId(companyId)(state),
    defaultCostType: selectDefaultCostType(companyId)(state),
    defaultPaymentType: selectDefaultPaymentType(companyId)(state),
    defaultCostCenter: selectDefaultCostCenter(companyId)(state),
    companyId,
    userId: state.auth.id ?? "",
  };
};

const mapDispatchToProps = {
  getCostCenters: costCentersActions.getCostCenters,
  getCostTypes: costTypesActions.getCostTypes,
  getPaymentTypes: paymentTypesActions.getPaymentTypes,
  getUserSettings: userSettingsActions.get,
  updateUserSettings: userSettingsActions.update,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface DefaultChoicesProps extends PropsFromRedux {
  companyId: string;
  userId: string;
}
type FormData = {
  defaultPaymentType: string;
  defaultCostType: string;
  defaultCostCenter: string;
};

const DefaultChoices = ({
  costCenters,
  paymentTypes,
  costTypes,
  defaultCostCenter,
  defaultPaymentType,
  defaultCostType,
  getCostCenters,
  getPaymentTypes,
  getCostTypes,
  getUserSettings,
  updateUserSettings,
  companyId,
  userId,
}: DefaultChoicesProps) => {
  const initialFormData: FormData = {
    defaultCostType: "",
    defaultCostCenter: "",
    defaultPaymentType: "",
  };
  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    getCostTypes({ companyId });
    getPaymentTypes({ companyId });
    getCostCenters({ companyId });
    getUserSettings({ companyId, userId });
  }, [
    companyId,
    userId,
    getCostTypes,
    getPaymentTypes,
    getCostCenters,
    getUserSettings,
  ]);
  useEffect(() => {
    setFormData((f) => ({ ...f, defaultCostCenter }));
  }, [defaultCostCenter, costCenters]);
  useEffect(() => {
    setFormData((f) => ({ ...f, defaultCostType }));
  }, [defaultCostType, costTypes]);
  useEffect(() => {
    setFormData((f) => ({ ...f, defaultPaymentType }));
  }, [defaultPaymentType, paymentTypes]);

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(undefined);
    setSubmitting(true);
    setSuccess(false);
    try {
      const updateSetting = (type: UserSettingType, value: string) =>
        updateUserSettings({
          companyId,
          userId,
          params: {
            type,
            value,
          },
        });
      updateSetting("defaultCostType", formData.defaultCostType);
      updateSetting("defaultPaymentType", formData.defaultPaymentType);
      updateSetting("defaultCostCenter", formData.defaultCostCenter);
      setSuccess(true);
    } catch (e) {
      setError("Något blev fel. Försök igen.");
    } finally {
      setSubmitting(false);
    }
  };
  const onSelectChange =
    (name: keyof FormData) => (e: React.ChangeEvent<{ value: unknown }>) => {
      const value = e.target.value as string;
      console.log(name, value);
      setFormData({ ...formData, [name]: value });
    };

  if (success) {
    return (
      <Confirmation
        title="Ange standardval"
        message="Dina standardval är uppdaterade"
      />
    );
  }

  return (
    <PageLayout title="Ange standardval">
      <p>
        Här kan du förenkla din kvittoregistrering, genom att ange vad som ska
        vara förvalt.
      </p>
      <form onSubmit={onSubmit}>
        {costCenters.length > 0 && (
          <FormControl margin="normal" fullWidth>
            <InputLabel id="label-costCenter">Kostnadsställe</InputLabel>
            <Select
              id="costCenter"
              labelId="label-costCenter"
              value={formData.defaultCostCenter}
              onChange={onSelectChange("defaultCostCenter")}
              disabled={!costCenters}
              native
            >
              <option value="" />
              {[...costCenters]
                .filter((c) => c.active)
                .sort((a, b) => (a.description > b.description ? 1 : -1))
                .map((costCenter: CostCenter) => (
                  <option value={costCenter.id} key={costCenter.id}>
                    {costCenter.description}
                  </option>
                ))}
            </Select>
          </FormControl>
        )}{" "}
        {costTypes.length > 0 && (
          <FormControl margin="normal" fullWidth>
            <InputLabel id="label-costType">Kostnadstyper</InputLabel>
            <Select
              id="costTypes"
              labelId="label-costTypes"
              value={formData.defaultCostType}
              onChange={onSelectChange("defaultCostType")}
              disabled={!costTypes}
              native
            >
              <option value="" />
              {[...costTypes]
                .filter((costType) => costType.enabled)
                .sort((a, b) => (a.description > b.description ? 1 : -1))
                .map((costType: CostType) => (
                  <option value={costType.id} key={costType.id}>
                    {costType.description}
                  </option>
                ))}
            </Select>
          </FormControl>
        )}
        {paymentTypes.length > 0 && (
          <FormControl margin="normal" fullWidth>
            <InputLabel id="label-paymentType">Betalsätt</InputLabel>
            <Select
              id="paymentType"
              labelId="label-paymentType"
              value={formData.defaultPaymentType}
              onChange={onSelectChange("defaultPaymentType")}
              disabled={!paymentTypes}
              native
            >
              <option value="" />
              {[...paymentTypes]
                .filter((paymentType) => paymentType.enabled)
                .sort((a, b) => (a.description > b.description ? 1 : -1))
                .map((paymentType: PaymentType) => (
                  <option value={paymentType.id} key={paymentType.id}>
                    {paymentType.description}
                  </option>
                ))}
            </Select>
          </FormControl>
        )}
        <Box marginBottom={5}></Box>
        {error && (
          <Box marginBottom={5}>
            <Alert severity="error">{error}</Alert>
          </Box>
        )}
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button
              component={Link}
              to="/settings"
              variant="contained"
              color="default"
              fullWidth
              disabled={submitting}
            >
              Avbryt
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={submitting}
            >
              Ändra
            </Button>
          </Grid>
        </Grid>
      </form>
    </PageLayout>
  );
};

export default connector(DefaultChoices);
