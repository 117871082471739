import { createSelector } from "@reduxjs/toolkit";
var selectProjects = function (state) { return state.projects; };
var selectProjectsByCompanyId = function (companyId) {
    return createSelector(selectProjects, function (projects) {
        return projects[companyId] || [];
    });
};
var selectOnGoingProjectsByCompanyId = function (companyId) {
    return createSelector(selectProjectsByCompanyId(companyId), function (projects) {
        return projects.filter(function (project) { return project.status === "ONGOING"; });
    });
};
export { selectProjects, selectProjectsByCompanyId, selectOnGoingProjectsByCompanyId, };
