import React, { useState, ChangeEvent } from "react";
import {
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";

type PasswordFieldProps = {
  id: string;
  label: string;
  disabled?: boolean;
  required?: boolean;
  value: string;
  onChange: (value: string) => void;
};
const PasswordField = ({
  id,
  label,
  disabled,
  required,
  value,
  onChange,
}: PasswordFieldProps) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <FormControl fullWidth margin="normal" disabled={disabled}>
      <InputLabel required={required} htmlFor={id}>
        {label}
      </InputLabel>
      <Input
        required
        id={id}
        name={id}
        type={passwordVisible ? "text" : "password"}
        value={value}
        onChange={onPasswordChange}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              color={passwordVisible ? "primary" : "inherit"}
              onClick={() => setPasswordVisible(!passwordVisible)}
            >
              <Visibility />
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
};
export default PasswordField;
