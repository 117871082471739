import { selectUserSetting } from "../userSettings/selectors";
import { createSelector } from "@reduxjs/toolkit";
var selectCostCenters = function (state) { return state.costCenters; };
var selectEnabledCostCentersByCompanyId = function (companyId) {
    return createSelector(selectCostCenters, function (costCenters) {
        var constCentersByCompany = costCenters[companyId] || [];
        return constCentersByCompany.filter(function (c) { return c.active; });
    });
};
var selectDefaultCostCenter = function (companyId) {
    return selectUserSetting(companyId, "defaultCostCenter");
};
export { selectCostCenters, selectEnabledCostCentersByCompanyId, selectDefaultCostCenter, };
