import React, { useState, ChangeEvent } from "react";
import { useSelector } from "react-redux";
import { PageLayout, Select } from "../../../components";
import { Button, Grid, FormControlLabel, Checkbox } from "@material-ui/core";
import api from "@kvittoapp/api-client";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Alert } from "@material-ui/lab";
import styles from "./Add.module.css";
import { selectActiveCompany } from "../../../ducks/active-company/selectors";
import { selectUserCompanyAccess } from "@kvittoapp/ducks/dist/users/selectors";
import axios from "axios";

type FormData = {
  enabled: boolean;
  role: "manager" | "staff";
};
type ResponseError = string;

const EditUser = () => {
  const { userId } = useParams();
  const companyId = useSelector(selectActiveCompany);
  const userCompanyAccess = useSelector(
    selectUserCompanyAccess({ companyId, id: userId as string })
  );
  const initialFormData: FormData = {
    enabled: !!userCompanyAccess?.enabled,
    role: userCompanyAccess?.role ?? "staff",
  };
  const initialError: ResponseError = "";
  const [formData, setFormData] = useState(initialFormData);
  const [submitting, setSubmitting] = useState(false);
  const [editSuccess, setEditSuccess] = useState(false);
  const [error, setError] = useState(initialError);
  const navigate = useNavigate();

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitting(true);
    setError(initialError);
    try {
      await api.updateUserCompanyAccess(userId as string, companyId!, {
        enabled: formData.enabled,
        role: formData.role,
      });
      setFormData(initialFormData);
      setSubmitting(false);
      setEditSuccess(true);
    } catch (e) {
      setEditSuccess(false);
      if (axios.isAxiosError(e)) {
        setError(e.message);
      }
    }
  };
  const onSelectChange = (name: keyof FormData) => (value: string) => {
    setFormData({ ...formData, [name]: value });
  };

  const onEnabledChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, enabled: e.target.checked });
  };

  if (editSuccess) {
    navigate("/settings/company/users");
  }

  const roles = [
    {
      value: "staff",
      description: "Personal",
    },
    {
      value: "manager",
      description: "Företagsadministratör",
    },
  ];

  return (
    <PageLayout title="Redigera användares företagskoppling">
      <form onSubmit={onSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.enabled}
                  color="primary"
                  onChange={onEnabledChange}
                />
              }
              label="Användaren är aktiv i bolaget"
            />
          </Grid>
          <Select
            key={`role-${formData.role}`}
            id="role"
            name="Roll"
            value={formData.role}
            onChange={onSelectChange("role")}
            required
            options={roles.map((role) => ({
              value: role.value,
              description: role.description,
            }))}
          />
          <Grid item xs={12}>
            {error && (
              <div className={styles.errorMessage}>
                <Alert severity="error">{error}</Alert>
              </div>
            )}
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Button
                component={Link}
                to="/settings/company/users"
                variant="contained"
                color="default"
                disabled={submitting}
                fullWidth
              >
                Avbryt
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={submitting}
                className={styles.submitButton}
                fullWidth
              >
                Uppdatera
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </PageLayout>
  );
};

export default EditUser;
