import currency from "currency.js";

const SEK = (value: currency.Any) =>
  currency(value, {
    symbol: " kr",
    precision: 2,
    separator: " ",
    pattern: "#!",
  }).format();

const currencies = { SEK };

export default currencies;
