import { createSelector } from "@reduxjs/toolkit";
import { selectUserSetting } from "../userSettings/selectors";
var selectCostTypes = function (state) { return state.costTypes; };
var selectDefaultCostType = function (companyId) {
    return selectUserSetting(companyId, "defaultCostType");
};
var selectCostTypesByCompanyId = function (companyId) {
    return createSelector(selectCostTypes, function (costTypes) { return costTypes[companyId] || []; });
};
var selectEnabledCostTypes = function (companyId) {
    return createSelector(selectCostTypesByCompanyId(companyId), function (costTypes) {
        return costTypes.filter(function (c) { return c.enabled; });
    });
};
export { selectCostTypes, selectDefaultCostType, selectCostTypesByCompanyId, selectEnabledCostTypes, };
