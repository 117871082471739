import React from "react";
import { PageLayout } from "../../components";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { green } from "@material-ui/core/colors";
import { Typography, Box } from "@material-ui/core";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { Button } from "@material-ui/core";

const ReActivateConfirmation = () => {
  const LinkBehavior = React.forwardRef<any, Omit<RouterLinkProps, "to">>(
    (props, ref) => <RouterLink ref={ref} to="/auth/login" {...props} />
  );
  return (
    <PageLayout title="Integrationen återaktiverad">
      <Box textAlign="center">
        <Box marginBottom={5}>
          <CheckCircleOutlineIcon style={{ color: green[500], fontSize: 50 }} />
          <Typography component="h5">
            Ditt integration är återaktiverad
          </Typography>
        </Box>
        <Button variant="outlined" component={LinkBehavior}>
          Logga in
        </Button>
      </Box>
    </PageLayout>
  );
};

export default ReActivateConfirmation;
