import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "@kvittoapp/api-client";
import { Button, Typography, Box } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import styles from "./Signup.module.css";
import { getErrorMessage } from "../../utils";
import axios from "axios";

interface ReActivateProps {
  authorizationCode: string;
}

const ReActivate = ({ authorizationCode }: ReActivateProps) => {
  const [submitting, setSubmitting] = useState(false);
  const [reActivated, setReActivated] = useState(false);
  const [errorMessage, setErrorMessage] = useState<undefined | string>(
    undefined
  );
  const navigate = useNavigate();

  const reActivate = async () => {
    setSubmitting(true);
    try {
      await api.createIntegrationActivation(authorizationCode);
      setReActivated(true);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.code === "404") {
          setErrorMessage("COMPANY_NOT_FOUND");
        } else if (e.code === "403") {
          setErrorMessage("FORTNOX_FORBIDDEN");
        } else {
          setErrorMessage("UNKNOWN_ERROR");
        }
      }
    } finally {
      setSubmitting(false);
    }
  };

  if (reActivated) {
    navigate("/signup/reactivate/confirmation");
  }

  return (
    <Box component="div">
      <Typography variant="subtitle1">Återaktivera</Typography>
      <p>
        Behöver du aktivera en inaktiverad integration med Fortnox? Klicka på
        knappen Återaktivera, så sköter sig resten automatiskt.
      </p>
      {errorMessage && (
        <div className={styles.errorMessage}>
          <Alert severity="error">{getErrorMessage(errorMessage)}</Alert>
        </div>
      )}
      <Button onClick={reActivate} disabled={submitting} fullWidth>
        Återaktivera
      </Button>
    </Box>
  );
};

export default ReActivate;
