import { createSelector } from "@reduxjs/toolkit";
var selectVouchers = function (state) { return state.vouchers; };
var selectVouchersByCompanyId = function (companyId) {
    return createSelector(selectVouchers, function (vouchers) { return vouchers[companyId] || []; });
};
var selectVoucherById = function (_a) {
    var id = _a.id, companyId = _a.companyId;
    return createSelector(selectVouchersByCompanyId(companyId), function (vouchers) {
        return vouchers.find(function (v) { return v.id === id; });
    });
};
export { selectVouchers, selectVoucherById, selectVouchersByCompanyId };
