import React, { useState, ChangeEvent } from "react";
import { connect } from "react-redux";
import { PageLayout } from "../../../components";
import { TextField, Button, Grid } from "@material-ui/core";
import api from "@kvittoapp/api-client";
import { Link, useNavigate } from "react-router-dom";
import { Alert } from "@material-ui/lab";
import styles from "./Add.module.css";
import { State } from "../../../store";
import { selectActiveCompany } from "../../../ducks/active-company/selectors";
import axios from "axios";

type FormData = {
  vat: number;
  accountNumber: string;
  description: string;
};

type ErrorResponse = {
  message: string;
};

interface AddCostTypeProps {
  companyId: string;
}

const AddCostType = ({ companyId }: AddCostTypeProps) => {
  const initialFormData: FormData = {
    vat: 0,
    accountNumber: "",
    description: "",
  };
  const defaultError: ErrorResponse = { message: "Ett okänd fel uppstod" };
  const initialError: ErrorResponse | undefined = undefined;
  const [formData, setFormData] = useState(initialFormData);
  const [submitting, setSubmitting] = useState(false);
  const [addSuccess, setAddSuccess] = useState(false);
  const [error, setError] = useState<ErrorResponse | undefined>(initialError);
  const navigate = useNavigate();

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitting(true);
    setError(initialError);

    try {
      await api.createCostType(companyId, {
        accountNumber: formData.accountNumber,
        vat: formData.vat,
        description: formData.description,
      });
      setAddSuccess(true);
      setFormData(initialFormData);
    } catch (e) {
      setAddSuccess(false);
      if (axios.isAxiosError(e)) {
        setError(e);
      }
    } finally {
      setSubmitting(false);
    }
  };
  const onFormDataChange =
    (name: keyof FormData) => (e: ChangeEvent<HTMLInputElement>) => {
      setFormData({ ...formData, [name]: e.target.value });
    };

  const onAccountNumberChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const accountNumber = e.target.value;
    setError(initialError);
    if (accountNumber.length !== 4) {
      onFormDataChange("accountNumber")(e);
      return;
    }
    api
      .getAccount(companyId!, accountNumber)
      .then((result) =>
        setFormData({
          ...formData,
          accountNumber,
          description: result.data.description,
        })
      )
      .catch((e) => {
        setError(e.response.data ? e.response.data : defaultError);
        setFormData({
          ...formData,
          accountNumber,
          description: "",
        });
      });
    setFormData({
      ...formData,
      accountNumber,
    });
  };

  if (addSuccess) {
    navigate("/settings/company/cost-types");
  }

  return (
    <PageLayout title="Lägga till kostnadstyp">
      <form onSubmit={onSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              margin="normal"
              required
              fullWidth
              autoFocus
              type="text"
              label="Konto"
              name="account"
              value={formData.accountNumber}
              onChange={onAccountNumberChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="normal"
              required
              fullWidth
              type="number"
              label="Moms"
              name="vat"
              value={formData.vat !== 0 ? formData.vat : ""}
              onChange={onFormDataChange("vat")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              type="text"
              label="Beskrivning"
              name="total"
              value={formData.description}
              onChange={onFormDataChange("description")}
            />
          </Grid>
          <Grid item xs={12}>
            {error && (
              <div className={styles.errorMessage}>
                <Alert severity="error">{error.message}</Alert>
              </div>
            )}
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Button
                component={Link}
                to="/settings/company/cost-types"
                variant="contained"
                color="default"
                disabled={submitting}
                fullWidth
              >
                Avbryt
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={submitting}
                className={styles.submitButton}
                fullWidth
              >
                Lägg till
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </PageLayout>
  );
};

const mapStateToProps = (state: State) => ({
  companyId: selectActiveCompany(state),
});

export default connect(mapStateToProps)(AddCostType);
