import { createSelector } from "@reduxjs/toolkit";
var selectUsers = function (state) { return state.users; };
var selectUsersByCompanyId = function (companyId) {
    return createSelector(selectUsers, function (users) {
        return users.filter(function (user) {
            return user.UserCompanyAccesses.find(function (companyAccess) { return companyAccess.companyId === companyId; });
        });
    });
};
var selectUserById = function (id) {
    return createSelector(selectUsers, function (users) { return users.find(function (user) { return user.id === id; }); });
};
var selectUserCompanyAccess = function (_a) {
    var companyId = _a.companyId, id = _a.id;
    return createSelector(selectUserById(id), function (user) {
        return user === null || user === void 0 ? void 0 : user.UserCompanyAccesses.find(function (access) { return access.companyId === companyId; });
    });
};
var selectUserRoleByCompanyId = function (_a) {
    var id = _a.id, companyId = _a.companyId;
    return createSelector(selectUserCompanyAccess({ companyId: companyId, id: id }), function (companyAccess) { return companyAccess === null || companyAccess === void 0 ? void 0 : companyAccess.role; });
};
export { selectUsers, selectUserById, selectUsersByCompanyId, selectUserCompanyAccess, selectUserRoleByCompanyId, };
