import React, { useState, ChangeEvent } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { TextField, Button, Grid, Box } from "@material-ui/core";
import { PageLayout } from "../../components";
import { Alert } from "@material-ui/lab";
import api from "@kvittoapp/api-client";
import { getErrorMessage } from "../../utils";
import Confirmation from "../../components/Confirmation/Confirmation";
import axios from "axios";

const RequestResetPassword = () => {
  const [email, setEmail] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<undefined | string>(
    undefined
  );

  const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      await api.requestResetPasswordToken(email);
      setSuccess(true);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        setErrorMessage(e.message);
      } else {
        setErrorMessage("UNKNOWN_ERROR");
      }
    } finally {
      setSubmitting(false);
    }
  };

  if (success) {
    return (
      <Confirmation
        title="Återställa lösenord"
        message="Nu har du fått ett mail med instruktioner för att återställa lösenordet."
      >
        <Box alignContent="center">
          <Button component={Link} to="/login" variant="outlined">
            Tillbaka till inloggning
          </Button>
        </Box>
      </Confirmation>
    );
  }

  return (
    <PageLayout backButton={{ url: "/login" }} title="Återställa lösenord">
      <p>
        Har du glömt ditt lösenord? Här kan du återställa det. Fyll i din
        e-postadress, så mailar vi en länk för att välja nytt lösenord.
      </p>
      <form onSubmit={onSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="E-postadress"
              name="email"
              autoComplete="email"
              type="email"
              autoFocus
              value={email}
              onChange={onEmailChange}
            />
          </Grid>

          <Grid item xs={12}>
            {errorMessage && (
              <Box marginBottom={5}>
                <Alert severity="error">{getErrorMessage(errorMessage)}</Alert>
              </Box>
            )}
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Button
                component={Link}
                to="/"
                variant="contained"
                color="default"
                disabled={submitting}
                fullWidth
              >
                Avbryt
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={submitting}
                fullWidth
              >
                Återställ
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </PageLayout>
  );
};

const mapDispatchToProps = {};

export default connect(null, mapDispatchToProps)(RequestResetPassword);
