import * as React from "react";
import { Typography, Backdrop, CircularProgress, Box } from "@material-ui/core";
import styles from "./Loader.module.css";

const Loader: React.FC = () => (
  <Backdrop invisible={true} open={true}>
    <div className={styles.container}>
      <Box marginBottom={3}>
        <Typography variant="h5" align="center">
          Laddar Enkla Kvitton
        </Typography>
      </Box>
      <CircularProgress color="primary" />
    </div>
  </Backdrop>
);

export default Loader;
