import React, { useEffect, useState, useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import { State } from "../../store";
import styles from "./File.module.css";
import api from "@kvittoapp/api-client";
import { usePdf } from "@mikecousins/react-pdf";
import { Pagination } from "@material-ui/lab";
import {
  Box,
  CircularProgress,
  Dialog,
  AppBar,
  IconButton,
  Toolbar,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { selectActiveCompany } from "../../ducks/active-company/selectors";
import { File } from "@kvittoapp/ducks/dist/vouchers/models";

interface ImageProps {
  url: string;
}

const ImageReceipt = ({ url }: ImageProps) => (
  <img src={url} className={styles.image} alt="Kvitto" />
);

interface PdfProps {
  url: string;
}

const PdfReceipt = ({ url }: PdfProps) => {
  const canvasRef = useRef(null);
  const [page, setPage] = useState(1);
  const { pdfDocument } = usePdf({
    file: url,
    page: page,
    canvasRef,
    scale: 0.5,
  });
  const onChange = (_: React.ChangeEvent<unknown>, value: number) =>
    setPage(value);
  return (
    <div>
      <Box display="flex" justifyContent="center" marginTop={1}>
        <canvas className={styles.pdf} ref={canvasRef} />
      </Box>
      {pdfDocument && (
        <Box display="flex" justifyContent="center">
          <Pagination
            count={pdfDocument.numPages}
            page={page}
            onChange={onChange}
          />
        </Box>
      )}
    </div>
  );
};

const mapStateToProps = (state: State) => {
  const companyId = selectActiveCompany(state);
  return {
    companyId,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface FileProps extends PropsFromRedux {
  open: boolean;
  file: File;
  onClose: () => void;
}

const ReceiptFile = ({
  companyId,
  file,
  open,
  onClose,
}: FileProps & PropsFromRedux) => {
  const [fileObjectUrl, setFileObjectUrl] = useState<string | undefined>(
    undefined
  );
  const [forcedDelayed, setForcedDelayed] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => setForcedDelayed(false), 500);
    return () => clearTimeout(timer);
  });
  useEffect(() => {
    if (!open) {
      return;
    }
    const getFileObject = async () => {
      const result = await api.getFile(companyId, file.id);
      const fileObjectUrl = URL.createObjectURL(result.data);
      setFileObjectUrl(fileObjectUrl);
    };
    getFileObject();
  }, [companyId, file.id, open]);

  if (!fileObjectUrl || forcedDelayed) {
    return (
      <Dialog fullScreen color="primary" open={open}>
        <AppBar position="relative">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box
          height="100%"
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress color="primary" />
        </Box>
      </Dialog>
    );
  }
  return (
    <Dialog fullScreen color="primary" open={open}>
      <AppBar position="relative">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {file.type.match(/image/g) ? (
        <ImageReceipt url={fileObjectUrl} />
      ) : (
        <PdfReceipt url={fileObjectUrl} />
      )}
    </Dialog>
  );
};

export default connector(ReceiptFile);
