import React from "react";
import {
  FormControl,
  InputLabel,
  Select as MUISelect,
} from "@material-ui/core";

type SelectOption = {
  value: string;
  description: string;
};

type SelectProps = {
  id: string;
  name: string;
  value: string;
  options: SelectOption[];
  required?: boolean;
  onChange: (value: string) => void;
};

const Select = ({
  id,
  name,
  value,
  onChange,
  options,
  required,
}: SelectProps) => {
  const onSelectChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const value = e.target.value as string;
    onChange(value);
  };
  return (
    <FormControl margin="normal" fullWidth required={required}>
      <InputLabel id={`label-${id}`}>{name}</InputLabel>
      <MUISelect
        id={id}
        labelId={`label-${id}`}
        value={value}
        onChange={onSelectChange}
        required={required}
        native
      >
        <option value="" />
        {[...options]
          .sort((a, b) => (a.description > b.description ? 1 : -1))
          .map((option: SelectOption) => (
            <option value={option.value} key={option.value}>
              {option.description}
            </option>
          ))}
      </MUISelect>
    </FormControl>
  );
};

export default Select;
