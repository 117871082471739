import { createSlice, PayloadAction, CaseReducer } from "@reduxjs/toolkit";
import { State } from "./models";

const initialState: State = "";

const setActiveCompany: CaseReducer<State, PayloadAction<string>> = (
  _: State,
  action
) => action.payload;

const slice = createSlice({
  name: "activeCompany",
  initialState,
  reducers: {
    setActiveCompany,
  },
});

export default slice.reducer;
const actions = slice.actions;
export { actions };
