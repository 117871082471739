import { AxiosProgressEvent } from "axios";
import React, { useState } from "react";
import {
  Button,
  LinearProgress,
  IconButton,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import api from "@kvittoapp/api-client";
import SearchIcon from "@material-ui/icons/Search";
import FilePreview from "../../components/File";
import { File } from "@kvittoapp/ducks/dist/vouchers/models";
import { Alert } from "@material-ui/lab";

type FileUploadInputProps = {
  companyId: string;
  uploadedFile: undefined | File;
  onChange: (file: File | undefined) => void;
};

const FileUploadInput = ({
  companyId,
  uploadedFile,
  onChange,
}: FileUploadInputProps) => {
  const [uploadProgress, setUploadProgress] = useState<undefined | number>(
    undefined
  );
  const [previewFile, setPreviewFile] = useState<undefined | string>(undefined);
  const [uploadError, setUploadError] = useState(false);

  const uploadFile = async (file: globalThis.File) => {
    setUploadError(false);
    const onUploadProgress = (progress: AxiosProgressEvent) => {
      const total = progress.total ?? 1;
      const loaded = progress.loaded;
      setUploadProgress(loaded / total);
    };
    if (file) {
      const fd = new FormData();
      fd.append("receipt-image", file, file.name);
      try {
        const result = await api.createFile(companyId, fd, onUploadProgress);
        onChange(result.data);
      } catch (e) {
        setUploadError(true);
        console.error(e);
      } finally {
        setUploadProgress(undefined);
      }
    }
  };

  const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length === 0) {
      console.error("no file");
      return;
    }
    const file = event.target.files[0];
    uploadFile(file);
  };

  const onDelete = async (companyId: string, fileId: string) => {
    try {
      await api.deleteFile(companyId, fileId);
    } finally {
      onChange(undefined);
    }
  };

  if (uploadProgress) {
    return (
      <LinearProgress variant="determinate" value={uploadProgress * 100} />
    );
  }

  if (uploadedFile) {
    return (
      <>
        {uploadedFile.name}
        <IconButton
          aria-label="preview"
          onClick={() => setPreviewFile(uploadedFile.id)}
        >
          <SearchIcon />
        </IconButton>
        <IconButton
          aria-label="delete"
          onClick={() => onDelete(companyId, uploadedFile.id)}
        >
          <DeleteIcon />
        </IconButton>
        <FilePreview
          open={!!previewFile}
          file={uploadedFile}
          onClose={() => setPreviewFile(undefined)}
        />
      </>
    );
  }

  return (
    <>
      <Button variant="contained" component="label">
        {"Lägg till fotografi av kvitto"}
        <input
          type="file"
          style={{ display: "none" }}
          onChange={onFileChange}
          onClick={(e) => (e.currentTarget.value = "")}
        />
      </Button>
      {uploadError && (
        <Alert severity="error">
          <Typography>
            Något blev fel när fotot försökte uppladdas. Försök igen.
          </Typography>
          <Typography>
            Ett vanlig fel är att fotot är för stort. Testa förminska fotot om
            det blir fel igen.
          </Typography>
        </Alert>
      )}
    </>
  );
};

export default FileUploadInput;
